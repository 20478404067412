.privicy {
  .privicy-heading {
    margin-top: 120px;
    position: relative;

    .privcy-text {
      position: absolute;
      bottom: -25%;
      text-align: center;
      width: 100%;
      font-weight: 900;
      opacity: 50%;
      font-size: max(35px, min(130px, 12vw));
      color: transparent;
      -webkit-text-stroke: 2px $secondary-main;
      text-stroke: 2px $secondary-main;
      text-transform: uppercase;
    }

    .privcy-full {
      font-size: max(24px, min(50px, 6vw));
      font-weight: 600;
      color: $primary-main;
      letter-spacing: -2px;
      text-align: center;
    }
  }

  .para-privicy {
    max-width: 1100px;
    text-align: center;
    color: $secondary-main;
    padding: 25px 12px;
  }

  .rows {
    max-width: 1100px;
    padding: 25px;

    .heading-privcy {
      font-size: min(60px, 5vw);
      color: $primary-info;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .text-justify {
      text-align: justify;
      font-size: min(18px, 3vw);
      margin-bottom: 50px;
    }
  }
}