.timeline {
  box-sizing: border-box;
  position: relative;
  z-index: 5;
  margin: 5rem 0;
  overflow: hidden;

  .bg-sec {
    background-color: $secondary-main;
    border-radius: 136px;
    text-align: center;
    color: $white;
    position: relative;
    overflow: hidden;

    .title-Text {
      height: 20%;
      margin-top: 2rem;

      .mainText {
        text-transform: uppercase;
        font-weight: bold;
        font-size: min(25px, max(38.21px, 5vw));
        margin-block: 2rem;

        > .changeColor {
          color: $primary-info;
        }
      }

      .secondary-text {
        color: #ffffff80;
        text-align: center;
        margin-inline: 2rem;
        font-size: max(16px, min(10px, 3vw));
      }
    }

    .image-div {
      margin: 3rem 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: stretch;
      height: 100%;
      position: relative;

      .center-div {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .left-side {
          img {
            position: relative;
            right: 20%;
            bottom: -5%;
            max-height: 100%;
            height: 100%;
          }
        }

        .right-side {
          img {
            position: relative;
            left: 20%;
            bottom: -5%;
            max-height: 100%;
            height: 100%;
          }
        }
      }

      .lft-sec {
        z-index: 1;
        width: 30%;

        .sub-head {
          width: 70%;
          font-weight: 800;
          text-transform: uppercase;
          font-size: 18px;
          text-align: left;
          margin-left: auto;
        }

        .ticket {
          .r-side {
            padding: 1rem 0;
            width: 20%;
            font-size: 24px;
            font-weight: 800;
            position: relative;
            border-right: 1px solid $primary-main;

            .sm-rnd {
              position: absolute;
              right: -5px;
              top: 50%;
              transform: translate(0, -50%);
              border-radius: 100%;
              background-color: $primary-main;
              width: 10px;
              height: 10px;
            }
          }

          .l-side {
            position: relative;
            padding: 1rem 0;
            width: 80%;

            .box-rounded {
              background-color: $secondary-main;

              align-items: stretch;
              //   padding: 0.7rem 0;
              width: 90%;
              height: 90%;
              border: 1px solid $primary-main;
              border-radius: 50px;
              transition: all 0.5s;

              .logo-round {
                margin: 5px;
                padding: 10px;
                border-radius: 50%;
                background: $primary-gradient;
                display: grid;
                place-items: center;
              }
            }

            &::after {
              content: "";
              position: absolute;
              z-index: -1;
              right: 40px;
              top: 50%;
              width: 3.5rem;
              height: 1px;
              background: $primary-main;
              opacity: 1;
              transition: all 0.5s ease;
            }

            &.active::after {
              right: -45px;
            }
          }

          &.active {
            .box-rounded {
              box-shadow: $primary-info 0px 5px 15px;
            }
          }
          &.active {
            .r-side {
              .sm-rnd {
                transition: all 0.5s;
                box-shadow: $tertiary-main 0px 0px 15px 8px;
              }
            }
          }
        }
      }

      .rig-sec {
        box-sizing: border-box;
        z-index: 1;
        width: 55%;
        height: 100%;
        padding: 3.5rem;
        padding-bottom: 0;

        .process-box {
          backdrop-filter: blur(4px);
          background-color: #fff;
          box-shadow: 12px 4px 23px 8px rgba(0, 0, 0, 0.1);
          color: #000;
          height: 100%;
          width: 100%;
          padding: 3.5rem;
          border: 1px solid $white;
          border-radius: 25px;
          text-align: left;

          .ph-heading,
          .ph-heading2 {
            font-size: 28px;
            font-weight: 500;
          }

          .ph-heading2 {
            color: $primary-info;
            margin-bottom: 3rem;
          }

          .desc {
            margin-bottom: 3rem;

            ul {
              list-style-type: disc;
            }
          }

          .card-process-div {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .process-card {
              padding: 0.5rem;
              border-radius: 15px;
              border: 1px solid $white;
              border-color: $primary-gradient;
              background-color: $secondary-main;
              width: 30%;
              box-shadow: $primary-main 0px 2px 8px 0px;
              background-color: #fff;

              .bordered-process {
                background: $primary-gradient;
                border-radius: 15px;
                padding: 0.5rem;
                color: #fff;
                //   border: 1px solid $white;
              }

              > p {
                padding: 0.5rem;
              }
            }
          }

          .para-procss {
            margin: 3rem 0;
          }

          .tags {
            margin-top: 1rem;
            flex-wrap: wrap;

            > div {
              box-sizing: border-box;
              width: 150px;
              height: 50px;
              border: 0.5px solid gray;
              border-radius: 100px;
              padding: 10px 20px;

              > img {
                width: 100%;
                height: 100%;
                // aspect-ratio: 3/1;
                object-fit: contain;
              }
            }
          }
        }
      }

      @media #{$lg-layout} {
        .lft-sec {
          width: 35%;
        }

        .rig-sec {
          width: 55%;

          .process-box {
            .card-process-div {
              flex-direction: column;
              flex-wrap: wrap;
              gap: 15px;

              .process-card {
                width: 100%;
              }
            }
          }
        }
      }

      @media #{$md-layout} {
        .lft-sec {
          width: 40%;
        }

        .rig-sec {
          width: 60%;

          .process-box {
            .card-process-div {
              flex-direction: column;
              flex-wrap: wrap;
              gap: 15px;

              .process-card {
                width: 100%;
              }
            }
          }
        }
      }

      @media #{$sm-layout} {
        flex-direction: column;
        align-items: center;

        .lft-sec {
          width: 90%;

          .ticket {
            .r-side {
              width: 15%;
              align-items: flex-start;
              justify-content: flex-start;

              .sm-rnd {
                top: 35px;
              }
            }

            .l-side {
              display: none;
            }
          }
        }

        .rig-sec {
          display: none;
        }

        .center-div {
          display: none;
        }
      }
    }

    @media #{$md-layout} {
      border-radius: 50px;
    }
  }
}
