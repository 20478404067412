.all-forms {
    position: relative;

    .admin-modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: 1000; 
        backdrop-filter: blur(20px);

        .admin-logo {
            width: 150px;
            height: 150px;
            margin: 10px auto;
        }
        .welcome-text{
            color: white;
            text-align: center;
            font-weight: 800;
            font-size: 40px;
        }
        .admin-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;

            .admin-email, .admin-password {
                width: 25%;
                padding: 15px;
                border-radius: 25px;
                border: 2px solid gray;
                transition: border-color 0.3s; 
                font-family: "Inter", sans-serif;
                text-align: center;

                &:focus {
                    border-color: #427dec; /* Change border color on focus */
                    outline: none; /* Remove default focus outline */
                }
            }

            .admin-verify {
                width: 15%;
                background-color: #427dec;
                color: white;
                border: none;
                padding: 10px;
                border-radius: 20px;
                cursor: pointer;
            }

            .admin-verify:hover {
                background-color: #15346d;
            }
        }
    }
    .verified{
        visibility: hidden;
    }


.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid gray;
    border-right-color: black;
    animation: l2 1s infinite linear;
  }
  @keyframes l2 {to{transform: rotate(1turn)}}
}
