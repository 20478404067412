.panel {
  display: none;
  width: 100%;
  margin: 1rem;

  border: 1px solid $white;
  border-radius: 12px;
  overflow: hidden;
  &__label {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px;
    font-weight: 500;
    font-size: 17px;
    font-family: inherit;
    transition: color 0.2s linear;

    &:focus {
      outline: none;
    }

    &__icon-arrow {
      transition: transform 0.2s linear;
      transform: rotate(0deg);
    }
  }

  &[aria-expanded="true"] {
    .panel__content {
      opacity: 1;
    }

    .panel__label {
      color: $white;
      background-color: $primary-main;

      &__icon-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  &__content {
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
    .process-box {
      box-sizing: border-box;
      background-color: $white;
      color: $black;
      width: 100%;
      padding: 2rem;
      text-align: left;
      .ph-heading,
      .ph-heading2 {
        font-size: 28px;
        font-weight: 500;
      }
      .ph-heading2 {
        color: $primary-info;
        margin-bottom: 3rem;
      }
      .desc {
        margin-bottom: 3rem;
        ul {
          list-style-type: disc;
        }
      }
      .process-card {
        box-sizing: border-box;
        padding: 0.5rem;
        border-radius: 15px;
        border: 1px solid $white;
        border-color: $primary-gradient;
        background-color: $white;
        color: $black;
        width: 30%;
        box-shadow: $primary-main 0px 2px 8px 0px;
        .bordered-process {
          color: $white;
          background: $primary-gradient;
          border-radius: 15px;
          padding: 0.5rem;
          //   border: 1px solid $white;
        }
        > p {
          padding: 0.5rem;
        }
      }
      .para-procss {
        margin: 3rem 0;
      }
      .tags {
        margin-top: 1rem;
        flex-wrap: wrap;
        > div {
          box-sizing: border-box;
          width: 150px;
          height: 50px;
          border: 1px solid $black;
          border-radius: 100px;
          padding: 10px 20px;
          > img {
            width: 100%;
            height: 100%;
            // aspect-ratio: 3/1;
            object-fit: contain;
          }
        }
      }
    }
  }

  @media #{$sm-layout} {
    display: block;
    &__content {
      .process-box {
        .card-process-div {
          flex-direction: column;
          flex-wrap: wrap;
          gap: 15px;
          .process-card {
            margin-bottom: 15px;
            width: 100%;
          }
        }
      }
    }
  }
  @media #{$small-mobile} {
    display: block;
    &__label {
      padding: 10px;
    }
    &__content {
      .process-box {
        padding: 10px;
        .card-process-div {
          flex-direction: column;
          flex-wrap: wrap;
          gap: 15px;
          .process-card {
            margin-bottom: 15px;
            width: 100%;
          }
        }
      }
    }
  }
}
