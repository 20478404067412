.field-box {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: flex-start;
    // width: 100%;

    .label-box {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 15px;

        .required {
            color: red;
            font-style: italic;
            font-size: 12px;
        }

        .label {
            color: black;
            font-weight: 600;

        }

    }

    .subtitle {
        color: #9A2DB1;
    }

    .field {
        width: 100%;
        border: 1px solid transparent;
        border-bottom: 2px solid black;
        padding: 20px;
        font-size: 18px;
    }

    .field:focus {
        outline: none;
    }

    .field::placeholder {
        font-size: 17px;
        opacity: 0.4;
    }
}