.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}
.content{
    display: flex;
    align-items: center;
    height: 80%;
    width: auto;
    .modal-img{
    height: 100%;
    width: 100%;
    border: 1px white solid;
    border-radius: 15px;

}}


