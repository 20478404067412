.contact {
  font-family: poppins, "sans-serif";
  box-sizing: border-box;

  button {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  input,
  textarea {
    color: #667085;
    font: inherit;
    margin: 0;
  }

  input {
    line-height: normal;
  }

  textarea {
    overflow: auto;
  }

  #container {
    border-radius: 16.75px;
    background-color: $tertiary-main;
    // max-width: 450px;
    // max-height: 0px;
    height: 100%;
    position: relative;
    width: 100%;
  }

  form {
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="text"],
  [type="email"],
  [type="number"],
  [type="textfield"],
  textarea {
    background: #ffff;
    border: 0.2px solid #667085;
    border-radius: 4.78px;
    color: #667085;
    font-size: 0.7em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0.5em 0 11px 0;
    padding: 6px 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    border: none;
    outline: none;

    // -moz-appearance: textfield;
    &.error {
      border: 2px solid crimson;
    }


  }

  input[type="text"]:focus,
  [type="email"]:focus,
  [type="number"]:focus,
  textarea:focus {
    outline: none;

    // padding: 0.5rem 0 0.875em 0.3rem;

  }

  .message {
    float: none;
  }

  .phoneNao {
    float: none;
    width: 100%;
  }

  .name {
    float: left;
    width: 47%;
  }

  .email {
    float: right;
    width: 47%;
  }

  textarea {
    line-height: 150%;
    height: 85px;
    resize: none;
    width: 100%;
  }

  ::-webkit-input-placeholder {
    color: #667085;
  }

  #form_button {
    background: $secondary-main;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    height: 35px;
    border-radius: 3.59px;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    // padding: 20px 35px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      background: rgba($color: $secondary-main, $alpha: 0.5);
      color: #ffffff;
    }

    &.loading {
      background: rgba($color: $secondary-main, $alpha: 0.5);
      color: #ffffff;
      pointer-events: none;
    }

    >.spin {
      animation: spin 0.5s linear infinite;
      font-size: 14px;
      margin-inline: 0.2rem;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  label {
    font-size: 14px;
  }


  @media #{$md-layout} {
    form {
      padding: 1rem;
      display: flex;
      flex-direction: column;
    }

    input[type="text"],
    [type="email"],
    [type="number"],
    [type="textfield"],
    textArea {
      margin-block: 0.4rem;
      padding: 0.2rem 0 0.3em 0.5rem;
      width: 100%;
    }

    .email {
      float: none;
      width: 100%;
    }

    .name {
      float: none;
      width: 100%;
    }
  }
}