// src/components/base/Table.scss
.table-container {
    width: 80%;
    margin: 20px auto;
    border-collapse: collapse;
}

.reusable-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.reusable-table th,
.reusable-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.reusable-table th {
    background-color: #f2f2f2;
    color: black;
}

.reusable-table tr {
    text-align: center;
}
.reusable-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.reusable-table tr:hover {
    background-color: #ddd;
}

.download-button {
    background-color: #427dec; /* Green */
    border: none;
    color: white;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}

.download-button:hover{
    background-color: #1a4594;
}

 .delete-button {
    border: none;
    background-color: transparent;
    color: red;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}


.delete-button:hover{
    color: #7e0303;
}

.isDisabled{
    background-color: rgb(136, 136, 136);
}
.isDisabled:hover{
    background-color: rgb(136, 136, 136);
    cursor: no-drop;
}