.sales {
  .sec9 {
    width: 100%;

    gap: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .sec9-right {
    max-width: 450px;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: hidden;

    &.active {
      max-width: 450px;
      max-height: 350px;
      background-color: $secondary-main;
      border-radius: 36px;
      padding-block: 20px;
      padding-right: 40px;
      padding-left: 20px;
      border-top: 7px solid $primary-main;
      border-bottom: 7px solid $primary-main;
      border-right: 7px solid $primary-main;
      box-shadow: 0px 29px 47.3px -20px rgba(0, 0, 0, 0.54);
      -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
      animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;



      .number_count {
        color: #fff;
        opacity: 1;
      }

      .borderImage {
        border: 5.05px solid $white;
      }

      .title {
        color: #fff;
      }

      .descp {
        opacity: 80%;
        color: #fff;
      }

      @-webkit-keyframes slide-in-blurred-left {
        0% {
          -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
          transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
          -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
          -webkit-filter: blur(40px);
          filter: blur(40px);
          opacity: 0;
        }

        100% {
          -webkit-transform: translateX(0) scaleY(1) scaleX(1);
          transform: translateX(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-filter: blur(0);
          filter: blur(0);
          opacity: 1;
        }
      }

      @keyframes slide-in-blurred-left {
        0% {
          -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
          transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
          -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
          -webkit-filter: blur(40px);
          filter: blur(40px);
          opacity: 0;
        }

        100% {
          -webkit-transform: translateX(0) scaleY(1) scaleX(1);
          transform: translateX(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-filter: blur(0);
          filter: blur(0);
          opacity: 1;
        }
      }


    }

    .number_wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .number_count {
      opacity: 0.1;
      color: rgb(0, 0, 0);
      letter-spacing: 0;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #fff;
      font-size: 190.73px;
      font-weight: 700;
      line-height: 1;
    }

    .borderImage {
      border: 5.05px solid $tertiary-main;
      border-radius: 30.29px;
      padding: 1rem 1rem;
    }

    .title {
      line-height: 1.53;
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
      color: $tertiary-main;
    }

    .descp {
      font-size: 16.73px;
      line-height: 153.7%;
      color: $secondary-main;
      opacity: 50%;
      margin-bottom: 20px;
    }
  }

  .sec9-left {
    position: sticky;
    top: 110px;
    height: 100%;
    max-height: 100vh;
    background-color: #fff;

    .headText {
      padding-left: 10%;

      .text {
        font-size: min(31px, 5vw);
        color: $secondary-main;
        opacity: 60%;
        font-weight: 300;
      }

      .boostText {
        opacity: 60%;
        font-size: min(48px, 7vw);
        color: $secondary-main;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1;
      }

      .salesText {
        font-size: min(138.34px, 16vw);
        line-height: 1;
        color: $primary-main;
        text-transform: uppercase;
        font-weight: 800;
      }
    }

    .subText {
      display: flex;
      width: 100%;

      .imageDiv {
        height: 364px;
        width: 364px;
        position: relative;
        z-index: -1;
      }

      .textDiv {
        padding: 1rem;
        background-color: $white;
        position: absolute;
        z-index: 100;
        left: auto;
        right: 0;
        max-width: 380px;
        line-height: 1.5;
        color: #17212197;
        font-size: min(16px, 3vw);
        // opacity: 50%;
      }
    }
  }

  @media #{$md-layout} {
    .sec9 {
      width: 100%;
      gap: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background-color: #fff;
    }

    .sec9-left {
      .imageDiv {
        display: none;
      }
    }

    .sec9-right {
      margin-top: 30px;
      margin-inline: 7px;
      max-height: 768px;

      &.active {
        margin-inline: 7px;
        max-height: 768px;
      }

      .number_wrapper {
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: space-between;
        margin-bottom: 2px;
      }

      .number_count {
        font-size: 5rem;
      }
    }
  }
}