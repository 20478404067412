.text-area-box {
    padding: 20px;

    .text-area-label-box {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 15px;

        .text-area-required {
            color: red;
            font-style: italic;
            font-size: 12px;
        }

        .text-area-label {
            color: black;
            font-weight: 600;

        }


    }

    .text-area-subtitle {
        color: #9A2DB1;
    }

    .text-area-field {
        width: 100%;
        border: 1px solid transparent;
        border-bottom: 2px solid black;
        padding: 10px;
        resize: none;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 15px;
    }

    .text-area-field:focus {
        outline: none;
    }

    .text-area-field::placeholder {
        font-size: 17px;
        opacity: 0.4;
    }
}