.fixed-form {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  backdrop-filter: blur(3px);

  box-sizing: border-box;

  .form-chat {
    padding: 15px 15px 30px 15px;
    width: 350px;
    border-radius: 36px;
    background-color: $secondary-main;
    color: $white;

    p {
      text-align: center;
      font-size: 16px;
    }

    div {
      box-sizing: border-box;
      width: 95%;

      label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7px;
        width: 95%;

        input,
        textarea {
          width: 100%;
          border: none;
          outline: none;
          border-radius: 5px;
          padding: 10px;

          &:disabled {
            background-color: $white;
          }

          &.error {
            border: 2px solid red;
          }
        }

        textarea {
          height: 180px;
        }
      }
    }

    .sub-btn-chat {
      margin-top: 10px;
      border: none;
      outline: none;
      color: $white;
      background-color: $primary-info;
      border-radius: 5px;
      width: 95%;
      padding: 5px;
      font-weight: 400;
      position: relative;

      &.loading {
        background-color: rgba($color: $primary-info, $alpha: 0.3);
        pointer-events: none;
      }

      >.spin {
        animation: spin 0.5s linear infinite;
        font-size: 18px;
        margin-inline: 0.2rem;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }

    }
  }

  .form-chat-after {
    padding: 15px 15px 30px 15px;
    width: 380px;
    border-radius: 36px;
    background-color: $secondary-main;
    color: $white;
    text-align: center;


    .thankyou {
      font-size: clamp(36px, 24px, 36px);
      text-align: center;
    }

    .infoText {
      margin-top: 1rem;
      font-size: clamp(14px, 24px, 14px);
      color: rgba($color: $white, $alpha: 0.8);
      text-align: center;
    }

    >.checkmark-icon {
      text-align: center;
      -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
      animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;


      @-webkit-keyframes slide-in-blurred-top {
        0% {
          -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
          filter: blur(40px);
          opacity: 0;
        }

        100% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
          transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-filter: blur(0);
          filter: blur(0);
          opacity: 1;
        }
      }

      @keyframes slide-in-blurred-top {
        0% {
          -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
          filter: blur(40px);
          opacity: 0;
        }

        100% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
          transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-filter: blur(0);
          filter: blur(0);
          opacity: 1;
        }
      }

    }
  }
}