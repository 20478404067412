@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

html,
body {
  font-family: "Inter", sans-serif !important;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-main;
    border-radius: 100px;
  }
}

img {
  // height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: var(--shadeDark);
}

ul {
  list-style: none;
}

.container {
  padding: 0 5%;
}

.white-space {
  white-space: nowrap;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

button {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  padding: 10px;
  border-radius: 40px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    opacity: 0.8;
  }
}

h1 {
  margin: 0;
}

p {
  margin: 0;
}

/** Utility classes **/
// .container {
//   width: 100%;
//   max-width: 900px;
//   margin: 0 auto;
// }

.container-fluid {
  width: 100%;
  height: 100%;
}

/** Display **/
.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

/** Align Items **/
.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-end {
  align-items: end;
}

/** Flex Direction **/
.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

/** Justify Content **/
.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.flex-flow-column {
  flex-flow: column;
}

/* Gap */
.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-3 {
  gap: 3px;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-64 {
  gap: 64px;
}

/* Text Alignment */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.text-decoration-none {
  text-decoration: none;
}

/* font size */

.fs-9 {
  font-size: 9px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-45 {
  font-size: 45px;
}

/* Font Weight */
.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.f-family-poppin {
  font-family: "Poppins", sans-serif;
}

.text-overflow-ellispsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/** Width **/
.width-50 {
  width: 50%;
}

/** Border Radius **/
.border-radius-5 {
  border-radius: 5px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-none {
  border: none;
}

/** Margin **/
.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px !important;
}

.m-auto {
  margin: auto;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-16 {
  margin-right: 16px;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-auto {
  margin-right: auto;
}

.ml-0 {
  margin-left: 0px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-16 {
  margin-left: 16px;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-auto {
  margin-top: auto;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-auto {
  margin-bottom: auto;
}

.my-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-20 {
  margin-block: 20px;
}

.my-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

/* Padding */
.p-0 {
  padding: 0;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-16 {
  padding: 16px;
}

.p-24 {
  padding: 24px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-16 {
  padding-right: 16px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-50 {
  padding-right: 50px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-20 {
  padding-top: 16px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-20 {
  padding-bottom: 16px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-auto {
  padding-bottom: auto;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-50 {
  padding-block: 50px !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.px-16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.pl-24 {
  padding-left: 24px;
}

.cursor-pointer {
  cursor: pointer;
}
