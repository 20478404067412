.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  // height: 100%;
  // max-height: 80vh;

  .text-color {
    color: $primary-main;
    border-bottom: 5px solid $primary-main;
    border-radius: 5px;
  }

  .hero-title {
    margin-block: 80px;

    p {
      opacity: 1;
      width: fit-content;
      margin: auto;
      font-size: min(45px, 7vw);
    }
  }



  .project-hero {
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .main-cards {
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .left-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    .card-left {
      display: flex;
      align-items: center;
      height: auto;
      gap: 16px;
      margin: 20px 0;

      .card-left-icon {
        border-radius: 50%;
        width: 70px;
        height: 70px;
        background-color: $secondary-main;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 4px solid transparent;
        transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

        img {
          height: 32px;
          width: 32px;
        }

        &.active {
          border-color: $primary-main;
        }
      }

      .card-left-title {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
      }
    }
  }

  .right-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;

    .card-right {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: auto;
      gap: 16px;
      margin: 20px 0;

      .card-right-icon {
        border-radius: 50%;
        width: 70px;
        height: 70px;
        background-color: $secondary-main;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 4px solid transparent;
        transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

        img {
          height: 32px;
          width: 32px;
        }

        &.active {
          border-color: $primary-main;
        }
      }

      .card-right-title {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;
      }
    }
  }

  .center-card {
    width: 100%;


    &.left {
      -webkit-animation: bounce-in-left 1.1s both;
      animation: bounce-in-left 1.1s both;
    }

    @-webkit-keyframes bounce-in-left {
      0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
      }

      38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;

      }

      55% {
        -webkit-transform: translateX(-68px);
        transform: translateX(-68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;

      }

      72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
      }

      81% {
        -webkit-transform: translateX(-28px);
        transform: translateX(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }

      90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }

      95% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }

      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
    }

    @keyframes bounce-in-left {
      0% {
        -webkit-transform: translateX(-600px);
        transform: translateX(-600px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
      }

      38% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
      }

      55% {
        -webkit-transform: translateX(-68px);
        transform: translateX(-68px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }

      72% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }

      81% {
        -webkit-transform: translateX(-28px);
        transform: translateX(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }

      90% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }

      95% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
      }

      100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
      }
    }


  }

  .ring-now {
    background-color: $secondary-main;
    color: white;
    width: 120px;
    margin: auto;
  }

  .book-appointment {
    color: $tertiary-main;
    background-color: transparent;
    border: 2px solid $tertiary-main;
    width: 280px;
    margin: auto;
    margin-top: 20px;
  }

  .caret-container {
    position: relative;
    bottom: -50px;
    z-index: 10;
    width: fit-content;
    margin: auto;
    border: 20px solid white;
    background-color: white;
    border-radius: 70px;
  }

  .caret-down {
    width: 70px;
    height: 70px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
    border: 6px solid $primary-main;
    background-color: $secondary-main;
    margin-top: 40px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

@media (max-width: 991px) {
  .hero-section {
    .main-cards {
      height: 100%;
      width: 100%;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .left-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;

      .card-left {
        display: flex;
        align-items: center;
        max-width: 350px;
        height: auto;
        gap: 16px;
        padding-left: 0px !important;

        .card-left-icon {
          border-radius: 50%;
          width: 70px;
          height: 70px;
          background-color: $secondary-main;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 4px solid transparent;
          transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

          img {
            height: 32px;
            width: 32px;
          }

          &.active {
            border-color: $primary-main;
          }
        }

        .card-left-title {
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
        }
      }
    }

    .right-card {
      .card-right {
        padding-right: 0px !important;
      }
    }
  }
}

.content-container {
  position: relative;
  // height: 500px;
  ;
  width: 100%;
  // overflow: hidden;
}

.image-fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image-visible {
  opacity: 1;
}