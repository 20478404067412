.main-container-testimonial {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  background: url("../../Assests/icons/Union.svg") no-repeat center center;
  background-size: cover;

  .p-absolute {
    position: relative;
    padding-top: 2rem;

    .invesment-text {
      position: absolute;
      bottom: 0;
      font-size: max(35px, min(144px, 9vw));
      font-weight: 800;
      color: transparent;
      -webkit-text-stroke: 2px $secondary-main;
      text-stroke: 2px $secondary-main;
      opacity: 10%;
      text-transform: uppercase;
    }

    .ring-now {
      z-index: 1;
      color: $primary-main;
      padding: min(10px, 2%) min(40px, 8%);
      margin: 0px auto;
      font-size: min(3.5vw, 60px);
      text-transform: capitalize;

      @media screen and (max-width: 767px) {
        padding: min(10px, 20px) min(40px, 20px); // Adjust as needed for smaller screens
      }
    }
  }

  .secondary-text {
    text-align: center;
    line-height: 153.7%;
    color: $secondary-main;
    font-size: 18px;
    opacity: 50%;
  }

  .testimonials-div {
    position: relative;
    // height: 100%;
    width: 100%;
    // background-color: pink;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .testimonial-cards {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 2rem;

      .imgae-left {
        position: absolute;
        left: -3%;
        top: -15%;
        max-width: 300px;
        height: 300px;

        filter: drop-shadow(-5px 3px 21px #535050);
        animation: 5s Infinite ease-out floating2;
        @media (max-width: 768px) {
          max-width: 200px;
          max-height: 200px;
          top: 5%;
        }

        @media #{$md-layout} {
          max-width: 200px;
          max-height: 200px;
          top: 5%;
        }

        @media #{$lg-layout} {
          top: -3%;
        }

        @media #{$laptop-device} {
          top: -8%;
        }
      }

      .imgae-right {
        position: absolute;
        right: 4%;
        bottom: 20%;
        filter: drop-shadow(-5px 3px 21px #535050);
        width: 200px;
        height: 200px;
        animation: 5s Infinite ease-out floating2;
        @media (max-width: 768px) {
          width: 100px;
          height: 100px;
          top: 50%;
          right: 4%;
        }

        @media #{$md-layout} {
          bottom: 35%;
        }

        @media #{$lg-layout} {
          bottom: 30%;
        }

        @media #{$laptop-device} {
          bottom: 30%;
        }
      }
    }

    .testimonials-text {
      position: relative;
      width: 100%;

      color: $white;

      /* SLIDER */
      .slider {
        max-width: 60rem;
        height: 35rem;

        margin: 0 auto;
        position: relative;

        /* IN THE END */
        overflow: hidden;
      }

      .slide {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        /* THIS creates the animation! */
        transition: transform 1s;
      }

      .slider__btn {
        position: absolute;
        top: 50%;
        z-index: 10;
        border: none;
        font-family: inherit;
        color: #333;
        border-radius: 50%;
        height: 5.5rem;
        width: 5.5rem;
        font-size: 3.25rem;
        cursor: pointer;
      }

      .slider__btn--left {
        left: 6%;
        transform: translate(-50%, -50%);
        height: 27px;
      }

      .text-div {
        position: relative;
        color: $white;
        font-weight: 600;
        font-size: min(20px, 3.5vw);
        top: 80%;
        z-index: 11;
      }

      .imageDiv-center {
        display: flex;
        height: auto;
        justify-content: center;
        align-items: center;
        width: 100%;
        // background-color: pink;
        // margin-block: 2rem;

        .margin-10 {
          width: 300px;
          height: 300px;
          margin-bottom: 4rem;
          :nth-child(1) {
            animation: 5s Infinite ease-out floating2;
          }
        }

        .brandlogos {
          animation: 5s Infinite ease-out floating;
        }
      }

      .slider__btn--right {
        right: 6%;
        transform: translate(50%, -50%);
        height: 27px;
      }

      /* TESTIMONIALS */
      .testimonial {
        width: 75%;
        position: relative;
      }

      .testimonial__header {
        font-size: min(35px, 6vw);
        font-weight: 600;
        margin-bottom: 1.5rem;
        text-align: center;
        text-transform: uppercase;
      }

      .testimonial__text {
        font-size: min(18px, 3vw);
        margin-bottom: 3.5rem;
        color: $white;
        text-align: center;
      }
    }
  }
}
