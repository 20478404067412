.faqs {
  .faqs-heading {
    margin-top: 120px;

    position: relative;
    .faq-text {
      position: absolute;
      bottom: -25%;
      text-align: center;
      width: 100%;
      font-weight: 900;
      opacity: 50%;
      font-size: max(70px, min(130px, 12vw));
      color: transparent;
      -webkit-text-stroke: 2px $secondary-main;
      text-stroke: 2px $secondary-main;
    }
    .faq-full {
      font-size: max(24px, min(50px, 6vw));
      font-weight: 600;
      color: $primary-main;
      letter-spacing: -2px;
    }
  }
  .para {
    max-width: 1100px;
    text-align: center;
    color: $secondary-main;
    padding: 25px 12px;
  }
  .list {
    margin-top: 5em;
    padding: 15px;
    max-width: 1400px;
    margin: 0 auto;
    .list-div {
      div {
        .list-heading {
          margin-top: 0.4em;
          padding: 10px 0;
          font-weight: 600;
          color: $black;
        }
        .list-desciption {
          padding: 10px 0;
          margin-bottom: 3.5em;
        }
      }
      &::before {
        content: "\2022";
        color: $black;
        font-size: 2.5em;
        margin-right: 0.5em;
        align-self: flex-start;
      }
    }
  }
}
