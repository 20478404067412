.fixed-div {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10000;
  width: 350px;

  .fixed-btn {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: $primary-gradient;
    animation: glow 1s ease-in-out infinite alternate;
  }

  .btn-hide {
    display: none;
  }

  .form-chat {
    box-sizing: border-box;
    padding: 15px 15px 30px 15px;

    width: 100%;
    border-radius: 36px;
    background-color: $secondary-main;
    color: $white;

    >.input-div {
      .error {
        color: crimson;
        font-size: 12px;
        margin-top: 0.3rem;
        text-align: left;
        margin-left: 0.6rem;
        margin-bottom: 0;

      }
    }

    p {
      text-align: center;
      font-size: 16px;
    }

    input,
    textarea {
      width: 90%;
      border: none;
      outline: none;
      border-radius: 5px;
      padding: 7px;
    }

    textarea {
      height: 180px;
    }

    .sub-btn-chat {
      border: none;
      outline: none;
      color: $white;
      background-color: $primary-info;
      border-radius: 5px;
      width: 95%;
      padding: 5px;
      font-weight: 400;

      &.loading {
        background: rgba($color: $tertiary-main, $alpha: 0.5);
        color: #ffffff;
        pointer-events: none;
      }

      >.spin {
        animation: spin 0.5s linear infinite;
        font-size: 14px;
        margin-inline: 0.2rem;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    }

    &.active-chat {

      -webkit-animation: scale-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: scale-in-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

      @-webkit-keyframes scale-in-br {
        0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          opacity: 1;
        }

        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          opacity: 1;

        }
      }

      @keyframes scale-in-br {
        0% {
          -webkit-transform: scale(0);
          transform: scale(0);
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          opacity: 1;
        }

        100% {
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          opacity: 1;
        }
      }
    }

    &.hide-chat {
      display: none;
    }

  }



  @media #{$sm-layout} {
    bottom: 15px;
    right: 20px;
  }

  @media #{$small-mobile} {
    right: 10px;

    .form-chat {
      width: 85%;
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px rgba(0, 90, 255, 0.5), 0 0 20px rgba(0, 90, 255, 0.5),
        0 0 30px rgba(0, 191, 255, 0.5), 0 0 40px rgba(0, 191, 255, 0.5),
        0 0 50px rgba(0, 191, 255, 0.5), 0 0 60px rgba(0, 191, 255, 0.5),
        0 0 70px rgba(0, 191, 255, 0.5);
    }

    // from {
    //   box-shadow: 0 0 10px rgba(0, 90, 255, 0.5), 0 0 20px rgba(0, 90, 255, 0.5),
    //     0 0 30px rgba(0, 173, 231, 0.5), 0 0 40px rgba(0, 173, 231, 0.5),
    //     0 0 50px rgba(0, 191, 255, 0.5), 0 0 60px rgba(0, 173, 231, 0.5),
    //     0 0 70px rgba(0, 173, 231, 0.5);
    // }

    to {
      box-shadow: 0 0 20px rgba(0, 90, 255, 0.1),
        0 0 30px rgba(0, 191, 255, 0.1), 0 0 40px rgba(0, 191, 255, 0.1),
        0 0 50px rgba(0, 191, 255, 0.1), 0 0 60px rgba(0, 191, 255, 0.1),
        0 0 70px rgba(0, 191, 255, 0.1), 0 0 80px rgba(0, 191, 255, 0.1);
    }
  }
}