.smm-form {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    .smm-form-info {
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 20px;

        .heading {
            color: #a71922;
        }

        .text {
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;

        }
    }
    .smm-detail{
        .error{
            padding:0 20px;
            color: red;
        }
    }
    .smm-buttons {
        display: flex;
        gap: 20px;
        padding: 20px;

        .submit-button,
        .cont-button {
            border-radius: 0;
            padding: 15px 40px;
            color: #9A2DB1;
        }

        .submit-button {
            background-color: #a71922;

            transition: all 0.75s linear;
        }

        .cont-button {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transition: all 0.75s all;
        }

        .cont-button:hover {
            box-shadow: #9A2DB1 0px 3px 8px;
        }

        .submit-button:hover {
            background-color: black;
            color: white;
        }
        .submit-button:disabled {
            background-color: #cccccc; /* Disabled button background color */
            color: #666666; /* Disabled button text color */
            cursor: not-allowed; /* Disabled cursor */
          }
    }

}

@media  (max-width: 768px) {
    .smm-buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;

      
    }
}