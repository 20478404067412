.cal-div-main {
  position: relative;
  margin-block: 2rem;
  box-sizing: border-box;

  // min-height: 130vh;
  .Clogo {
    z-index: -10;
    position: absolute;
    top: -25%;
    left: -10%;
    width: min(1400px, 100vw);
    height: min(1400px, 100vw);
  }

  width: 100%;

  .cal-inner-div {
    background-color: $white;
    border-radius: 36px;
    box-shadow: 0px 4px 45.7px 0px rgba(0, 0, 0, 0.25);
    width: min(1200px, 80vw);
    margin: 0 auto;
    padding: 3rem;

    .first-chi {
      width: 40%;
      color: $secondary-info;
      padding: 0px 0px;

      .cal-heading {
        color: $black;
        margin: 10px 0;
        font-size: min(36px, 4vw);
        font-weight: 800;
      }
    }

    .sec-chi {
      width: 60%;
      padding: 40px 30px;

      .calendar {
        z-index: 5;
        overflow: hidden;
        width: calc(100% + 20%);
        height: 100%;
        border-radius: 30.77px;
        background-color: $secondary-main;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        .calen-first {
          width: 25%;
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .inner-opt {
            width: 100%;
          }

          .year {
            font-size: min(18px, 5vw);
            font-weight: 500;
          }

          .prev-month {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-block: 2rem;
            // font-size: min(40px, max(40px, 2vw));
            color: $white;
            cursor: pointer;
          }

          .month {
            // font-size: min(30px, max(40px, 2vw));
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;

            &.animate {
              -webkit-animation: bounce-in-left 1.1s both;
              animation: bounce-in-left 1.1s both;
            }

            @-webkit-keyframes bounce-in-left {
              0% {
                -webkit-transform: translateX(-600px);
                transform: translateX(-600px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
                opacity: 0;
              }

              38% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
                opacity: 1;
              }

              55% {
                -webkit-transform: translateX(-68px);
                transform: translateX(-68px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
              }

              72% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
              }

              81% {
                -webkit-transform: translateX(-28px);
                transform: translateX(-28px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
              }

              90% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
              }

              95% {
                -webkit-transform: translateX(-8px);
                transform: translateX(-8px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
              }

              100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
              }
            }

            @keyframes bounce-in-left {
              0% {
                -webkit-transform: translateX(-600px);
                transform: translateX(-600px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
                opacity: 0;
              }

              38% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
                opacity: 1;
              }

              55% {
                -webkit-transform: translateX(-68px);
                transform: translateX(-68px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
              }

              72% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
              }

              81% {
                -webkit-transform: translateX(-28px);
                transform: translateX(-28px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
              }

              90% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
              }

              95% {
                -webkit-transform: translateX(-8px);
                transform: translateX(-8px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
              }

              100% {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
              }
            }
          }

          .next-month {
            margin-block: 2rem;
            font-size: min(40px, max(40px, 2vw));
            color: $white;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .calen-second {
          position: relative;
          width: 75%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .circle {
            position: absolute;
            background-color: $tertiary-main;
            height: 200%;
            top: -50%;
            // transform: translate(-3%, -20%);
            left: 0%;
            width: calc(100% + 20%);
            border-radius: 50%;
            z-index: 3;
          }

          .calendar-data {
            position: relative;
            z-index: 4;
            font-size: 18.95px;
            font-weight: 400px;
          }

          .calendar-comp {
            position: relative;
            z-index: 4;
            width: calc(100% - 15%);
          }
        }
      }
    }
  }

  .modal {
    position: fixed;
    left: 0;
    top: 20%;
    width: 100%;
    height: 50%;
    // background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 9999999;
  }

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 1rem 1.5rem;
    width: 24rem;
    border-radius: 0.5rem;
    text-align: center;
    border: black solid 1px;

    h1 {
      font-weight: 600;
    }

    .chnge-color {
      color: $primary-main;
    }

    p {
      color: $secondary-info;
    }

    >.checkmark-icon {
      color: $secondary-info;
      -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
      animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;


      @-webkit-keyframes slide-in-blurred-top {
        0% {
          -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
          filter: blur(40px);
          opacity: 0;
        }

        100% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
          transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-filter: blur(0);
          filter: blur(0);
          opacity: 1;
        }
      }

      @keyframes slide-in-blurred-top {
        0% {
          -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
          filter: blur(40px);
          opacity: 0;
        }

        100% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
          transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-filter: blur(0);
          filter: blur(0);
          opacity: 1;
        }
      }

    }

    .call-btn {
      width: fit-content;
      padding: 0.5rem 1.5rem;
      background-color: $tertiary-main;
      color: $white;
      border-radius: 5px;
    }
  }

  .close-button {
    // float: right;
    margin-left: auto;
    // width: 100%;
    width: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: lightgray;
  }

  .close-button:hover {
    background-color: darkgray;
  }

  .show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }


  @media only screen and (min-width: 1200px) {
    height: 100vh;
  }

  @media #{$smlg-device} {
    .Clogo {
      top: -10%;
    }

    .cal-inner-div {
      flex-direction: column;
      gap: 3rem;

      .first-chi {
        // gap: 0;
        padding: 0;
        flex-direction: row;
        width: 100%;
      }

      .sec-chi {
        width: 100%;
        padding: 0;

        .calendar {
          width: 100%;
          height: 50vw;
          padding: 2rem 0;
        }
      }
    }


  }

  @media #{$large-mobile} {
    .Clogo {
      top: 0%;
    }

    .cal-inner-div {
      box-sizing: border-box;
      flex-direction: column;
      width: 90%;
      padding: 1rem;
      gap: 3rem;

      .first-chi {
        gap: 3rem;
        padding: 0 0;
        flex-direction: column;
        width: 100%;
      }

      .sec-chi {
        width: 100%;
        padding: 0;

        .calendar {
          padding: 0;
          width: 100%;
          height: fit-content;
          flex-direction: column;

          .calen-first {
            padding: 1rem 0;
            width: 100%;
            justify-content: space-evenly;

            .inner-opt {
              width: 100%;
              display: flex;
              justify-content: space-evenly;
              align-items: center;

              .month {
                font-size: 20px;
              }

              .prev-month,
              .next-month {
                rotate: 270deg;
                margin: 0;
              }
            }
          }

          .calen-second {
            padding: 1rem;
            // border-top-left-radius: 30%;
            // border-top-right-radius: 30%;
            // border-radius: 999em 999em 40px 40px;
            background-color: $tertiary-main;
            width: 100%;
            z-index: 100;
            height: fit-content;

            .circle {
              display: none;
            }

            .calendar-comp {
              width: 100%;

              // scale: 0.8;
            }
          }
        }
      }
    }

    .modal-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 1rem 1.5rem;
      width: calc(100% - 30%);
      border-radius: 0.5rem;
      text-align: center;
    }
  }
}