.calendar-container {
  width: 100%;
  margin-top: 20px;
}

.weekdays {
  display: flex;
}

.weekday {
  flex: 1;
  padding: 8px;
  text-align: center;
}

.calendar-week {
  display: flex;
  margin-bottom: 0.3rem;
}

.calendar-day {
  width: calc(100% / 7);
  height: calc(100% / 7);
  text-align: center;
  box-sizing: border-box;
  padding-bottom: 2px;

  .days {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid $white;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    

    &.today {
      background-color: rgba($color: $secondary-main, $alpha: 0.5);
      box-shadow: 0px 0px 15px 5.43px rgba(58, 69, 69, 0.24);
      border: none;
    }

    &.active {
      background-color: $primary-main;
      box-shadow: 0px 0px 15px 5.43px rgba(58, 69, 69, 0.24);
      border: none;
      pointer-events: none;
    }

    &.selected {
      background-color: rgba($color: $secondary-main, $alpha: 0.9);
      box-shadow: 0px 0px 15px 5.43px rgba(58, 69, 69, 0.44);
      border: none;
    }
  }
  .daysDisable {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid #ccc; /* Lighter border to indicate non-active */
    font-size: 14px;
    text-align: center;
    cursor: not-allowed; /* Change cursor to indicate non-interactivity */
    font-weight: bold;
    color: #ccc; /* Grey out the text to show it's disabled */
    background-color: #f9f9f9;
    

    &.today {
      background-color: rgba($color: $secondary-main, $alpha: 0.5);
      box-shadow: 0px 0px 15px 5.43px rgba(58, 69, 69, 0.24);
      border: none;
    }

    &.active {
      background-color: $primary-main;
      box-shadow: 0px 0px 15px 5.43px rgba(58, 69, 69, 0.24);
      border: none;
      pointer-events: none;
    }

    &.selected {
      background-color: rgba($color: $secondary-main, $alpha: 0.9);
      box-shadow: 0px 0px 15px 5.43px rgba(58, 69, 69, 0.44);
      border: none;
    }
  }
}

.empty-cell {
  width: calc(100% / 7);
  box-sizing: border-box;
}

@media #{$large-mobile} {
  .calendar-container {
    // scale: 0.8;
    margin-top: 0;

    .calendar-day {
      .days {
        border-radius: 50%;
        border: none;
      }
    }
  }

  .weekdays {
    width: 100%;
  }
}

@media #{$small-mobile} {
  .calendar-container {
    // scale: 0.8;
    margin-top: 0;

    .calendar-day {
      .days {
        border-radius: 50%;
        border: none;
      }
    }
  }

  .weekdays {
    width: 100%;
    font-size: 12px;
  }
}