@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

.modal-content {
    position: relative;
    background-color: white;
    height: 90%;
    width: 60%;
    border-radius: 8px;
    overflow-y: auto; 
    overflow-x: hidden;
    
    &::-webkit-scrollbar {
        display: none;
    }
    
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.content-header{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;

    .blue-ellipse, .black-ellipse {
        position: absolute;
        z-index: 1; 
    }

    .blue-ellipse{
        height: 450px;
        width: 450px;
        top: -3%; 
        right: 0%; 
    }
    
    .black-ellipse{
        height: 300px;
        width: 300px;
        left: -13%; 
        top: 3%; 
    }
    
    .case-study-head{
        box-sizing: border-box;
        position: relative; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;  
        width: 100%;
        padding: 0 50px;
        margin: 0 auto;
        margin-top: 50px;

        .intro{
            display: flex;
            flex-direction: column;

            .case-text{
                font-family: "Inter", sans-serif;
                color: #172121;
                font-size: 25;
            }
            .project-text{
                font-family: "Inter", sans-serif;
                color: #005AFF;
                font-size: 42px;
                font-weight: 700;
            }
        }
        .laptop{
            position: relative;
            .laptop-img{
                height: 350px;
                width: 670px;
            }
            .inside-laptop{
                transform: scale(0.5);
                position: absolute;
                top: -23%;
                left: 1%;
                height: 478px;
                width: 655px;
z-index: -1;
            }
    
        }

    }

 
    
    
}
.case-study-main{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .challenge{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        .challenge-text{
            color: #3A4545;
            font-size: 25px;
            font-weight: 600;
            padding: 5px;
        }
        .challenge-box{
            padding: 20px 40px;
            border-radius: 20px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                        display: flex;
            justify-content: space-between;
            gap: 10px;
            .challenge-child{
                color: #000;
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 14px;
            }
        }
    }
    .solution{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .solution-head{
            color: #3A4545;
            font-size: 25px;
            font-weight: 600;
            padding: 5px;
        }
        .solution-text{
            padding: 10px;

                color: #000;
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 14px;
        }
    }
    
    .steps{
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 50px;
        .step12{
            display: flex;
            justify-content: space-between;
            // flex-wrap: wrap;
            gap: 20px;        
    }
    }
    .step{
        padding: 20px 40px;
        border-radius: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                display: flex;
        justify-content: space-between;        
        align-items: center;
        color: black;
        // width: 50%;    
        gap: 20px;
        .step-info{
            width: 70%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .step-number{
                color: #3A4545;
            }
            .step-heading{
                font-family: "Inter", sans-serif;
                color: #005AFF;
                font-size: 25px;
                font-weight: 700;
            }
            .step-desc{
                color: #3A4545;
                font-size: 15px;
            }

        }
        .step-img-box{
            width: 30%;
            .step-img{
            border-radius: 20px;
                height: 180px;
            }
        }
    }

}


@media (max-width: 1200px) {
    .case-study-head {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        // padding: 20px; /* Reduced padding */

        .intro {
            width: 100%;
            text-align: center;
            transform: translateX(0%);
            .case-text{
                font-size: 30px;
            }
            .project-text{
                font-size: 100px;
            }
        }

        .laptop  {
            width: 100%; /* Ensure full width for the laptop image container */
            padding: 0;
            transform: translateX(-10%) scale(1);
            // background-color: red;
            position: relative; /* Important for positioning inside-laptop correctly */
        }
        .laptop-img{
            height: 370px;
            width: 680px;
        }

        .inside-laptop{
            transform: scale(1); /* Adjust scale to fit inside the laptop image if needed */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); /* Center the element */
            width: 90%; /* Reduced width to ensure it stays within the parent */
            height: auto; /* Adjust height automatically to maintain aspect ratio */
            z-index: -1;
        }
    }

    .challenge{
        .challenge-box{
            display: flex;
            flex-direction: column;
        }
    }
    .steps{
        display: flex;
        flex-direction: column;
        gap: 40px;
        .step12{
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            // gap: 20px;        
    }
    }
}

@media (max-width: 980px) {
    .case-study-head {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 0 0;
        // padding: 20px; /* Reduced padding */

        .intro {
            padding: 0;
            width: 100%;
            text-align: center;
            // transform: translateX(0%);
            .case-text{
                font-size: 30px;
            }
           
        }

        .laptop  {
            width: 100%; /* Ensure full width for the laptop image container */
            padding: 0;
            transform: translateX(-20%) scale(0.8);
            // background-color: red;
            position: relative; /* Important for positioning inside-laptop correctly */
        }
        .laptop-img{
            height: 370px;
            width: 680px;
        }

        .inside-laptop{
            transform: scale(1); /* Adjust scale to fit inside the laptop image if needed */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); /* Center the element */
            width: 90%; /* Reduced width to ensure it stays within the parent */
            height: auto; /* Adjust height automatically to maintain aspect ratio */
            z-index: -1;
        }
    }

    .challenge{
        margin-top: 50px;
        .challenge-box{
            display: flex;
            flex-direction: column;
        }
    }
   
    .steps{
        display: flex;
        flex-direction: column;
        gap: 40px;
        .step12{
            display: flex;
            justify-content: space-between;
            // flex-wrap: wrap;
            gap: 20px;        
    }
    .step{
        padding: 10px 0;
        border-radius: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                display: flex;
        flex-direction: column;        
        color: black;
        // width: 50%;    
        gap: 20px;
        .step-info{
            // width: 60%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .step-number{
                color: #3A4545;
            }
            .step-heading{
                font-family: "Inter", sans-serif;
                color: #005AFF;
                font-size: 25px;
                font-weight: 700;
            }
            .step-desc{
                color: #3A4545;
                font-size: 15px;
            }

        }
        .step-img-box{
            width: 60%;
            .step-img{
            border-radius: 20px;
                height: 200px;
            }
        }
    }
    }
}

@media (max-width: 780px) {
    .case-study-head {
        .laptop  {
            transform: translateX(-40%) scale(0.8);
        }
     
    }

}
@media (max-width: 700px) {
    .case-study-head {
        .laptop  {            
            transform: translateX(-60%) scale(0.8);
        }     
    }
}

@media (max-width: 600px) {
    .case-study-head {
        .laptop  {            
            transform: translateX(-75%) scale(0.7);
        }     
    }
}