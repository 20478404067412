.section_numbers {
  z-index: 2;
  position: relative;
  overflow: hidden;
  background-color: #020407 !important;
  width: 100%;

  .numbers_screen {
    z-index: -1;
    opacity: 0.5;
    perspective: 1412px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
  }

  .numbers_screen-left {
    z-index: -1;
    transform-origin: 0%;
    transform-style: preserve-3d;
    justify-content: flex-start;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: auto;
    transform: rotate3d(0, 1, 0.0000949539, 80deg);
  }

  .numbers_image {
    width: 40vw;
    perspective: 100px;
    object-fit: contain;
    flex: none;
    margin-left: 3rem;
    margin-right: 3rem;
    transform: perspective(100px);
  }

  .numbers_screen-right {
    z-index: -1;
    transform-origin: 100%;
    transform-style: preserve-3d;
    justify-content: flex-end;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;
    transform: rotateX(0) rotateY(-80deg) rotate(0);
  }

  .numbers_grad {
    z-index: 0;
    width: 290vw;
    height: 50vw;
    transform-origin: 0%;
    transform-style: preserve-3d;
    background-image: linear-gradient(270deg, #242022 50%, transparent 80%);
    flex: none;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: auto;
    transform: rotate3d(0, 1, 0.0000949539, 80deg);

    &.is-right {
      transform-origin: 100%;
      transform-style: preserve-3d;
      background-image: linear-gradient(90deg, #242022 50%, transparent 80%);
      top: 0%;
      bottom: 0%;
      left: auto;
      right: 0%;
      transform: rotateX(0) rotateY(-80deg) rotate(0);
    }
  }

  .numbers_screen-box-left,
  .numbers_screen-box-right {
    flex: none;
    display: flex;
  }

  .numbers_bg-grad {
    z-index: 1;
    background-image: radial-gradient(
      circle,
      rgba(24, 23, 24, 0.1),
      transparent 50%
    );
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .numbers_grid {
    grid-column-gap: 3.25rem;
    color: #fff;
    font-weight: 500;
    display: flex;
  }

  .numbers_item {
    position: static;
  }

  .numbers_text {
    color: #fff;
    margin-left: 3.5rem;
    font-size: 6rem;
    font-weight: 700;
    line-height: 1.35;
    position: relative;
  }

  .numbers_super {
    height: 4.3rem;
    -webkit-text-fill-color: transparent;
    background-image: url(../../assets/images/63e15e3eb02434c92685f63b_mesh-gradient.jpg);
    background-position: 100%;
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 6rem;
  }

  .numbers_super.is-v2 {
    background-image: url(../../assets/images/mesh-gradient.jpg);
    background-position: 0 0;
    background-size: contain;
  }

  .numbers_super.is-v3 {
    background-image: url(../../assets/images/63e15f40f58bf3766d1e04d9_mesh-gradient-.jpg);
    background-position: 100%;
    background-size: contain;
  }

  .text-size-large {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .padding-section-xlarge {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .glass-lining_div {
    z-index: 1;
    width: 100%;
    height: 1px;
    max-width: 80rem;
    opacity: 0.6;
    background-image: radial-gradient(circle, #fff, transparent);
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;

    &.is-top {
      top: 0%;
      bottom: auto;
      left: 0%;
      right: 0%;
    }
  }

  .section_header.text-align-center {
    z-index: 1;
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .section_cta {
    border-radius: 0px;
  }

  .numbers_screen-box-left {
    transform: translate(0%, 0px);
    /* Set the animation properties */
    animation-name: bgshots;
    animation-duration: 45s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  /* Define the keyframes for the animation */
  @keyframes bgshots {
    from {
      transform: translate(0%, 0px);
    }

    to {
      transform: translate(-100%, 0px);
    }
  }

  .numbers_screen-box-right {
    transform: translate(0%, 0px);
    /* Set the animation properties */
    animation-name: bgshotsright;
    animation-duration: 45s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  /* Define the keyframes for the animation */
  @keyframes bgshotsright {
    from {
      transform: translate(0%, 0px);
    }

    to {
      transform: translate(100%, 0px);
    }
  }

  @media (max-width: 768px) {
    .numbers_grid {
      grid-column-gap: 3.25rem;
      color: #fff;
      font-weight: 500;
      display: flex;
      flex-direction: column;
    }
  }
}
