$padding: 4%;
$margin: 0 auto;
$maxWidth: 800px;
$borderRadius1: 3%;
$borderRadius2: 0.5%;
$borderRadius3: 5%;
$aspectRatio1: 67%;
$aspectRatio2: 100%;
$aspectRatio3: 33%;
$aspectRatio4: 1000%;
$notchWidth: 14%;

// colors
$primary-gradient: linear-gradient(270deg, #00bfff 0%, #005aff 100%);

$primary-main: rgba(0, 90, 255, 1);
$primary-info: rgba(0, 191, 255, 1);
$secondary-main: #172121;
$tertiary-main: #07b1fe;
$warning-main: #f59e0b;
$purple: #4133b7;
$gray-light: rgb(224, 224, 224);
$secondary-info: #8b9090;

//common color
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);

$smlg-device: "only screen and (max-width: 1199px)";
$extra-device: "only screen and (min-width: 1600px) and (max-width: 1919px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
$large-mobile: "only screen and (max-width: 575px)";
$small-mobile: "only screen and (max-width: 479px)";