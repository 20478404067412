.loader-container {
    // display: flex;
    justify-content: center;
    align-items: center;
    // background-color: 'red' !important;
    height: 100vh !important;
    width: 100% !important;


    @media #{$small-mobile} {
        video {
            width: 90%;
        }
    }
}