.primary-button {
  // width: 162px;
  // height: 36px;
  border-radius: 46px;
  background-color: transparent;
  color: $primary-info;
  border-color: $primary-info;
  border-width: 1px;
}

.secondary-button {
  height: 36px;
  background-color: transparent;
  color: $white;
  border-color: transparent;
}
