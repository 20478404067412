.projects {
  background: url("../../Assests/images/backImageDesign.png") no-repeat top center;
  background-size: cover;
  color: white;
  padding-top: 150px;
  padding-bottom: 20px;
  position: relative;

  .design {
    position: absolute;
    width: 200px;
    left: 0;
    top: 80px;
  }

  .text-row {
    display: flex;
    align-items: center;
    border-top: 1px solid $tertiary-main;
    border-bottom: 1px solid $tertiary-main;
    padding: 20px;
    border-radius: 15px;
    width: 85%;
    max-width: 1400px;
    margin: auto;
    background-color: rgba($color: $black, $alpha: 0.5);
    position: relative;
    z-index: 6;

    .heading {
      width: 50%;

      p {
        font-size: 35px;
      }

      h1 {
        font-size: min(50px, max(85px, 5vw));
      }
    }

    .desc {
      width: 50%;
      font-size: 18px;
      font-weight: 100;
      line-height: 35px;
    }

    @media #{$sm-layout} {
      flex-direction: column;
      padding: 10px;
      align-items: flex-start;

      .heading {
        width: 100%;

        p {
          font-size: 35px;
          width: 100%;
        }

        h1 {
          font-size: min(50px, max(85px, 5vw));
        }
      }

      .desc {
        width: 100%;
      }
    }

    // @media #{$sm-layout} {

    // }
  }

  .heading-portfolio {
    text-align: center;
    margin: 50px 0;

    .heading-port {
      color: transparent;
      -webkit-text-stroke: 0.7px $white;
      // -webkit-text-stroke: 0.7px $white;
      letter-spacing: 0.3rem;
      font-weight: 1000;
      text-transform: uppercase;
      font-size: min(48px, 9vw);
    }
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    border: 2px solid white;
    border-radius: 40px;
    max-width: 1200px;
    margin: 0 auto;

    @media #{$md-layout} {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .tab {
    padding: 15px 25px;
    border-radius: 40px;
    margin: 5px;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      background-color: $primary-main;
    }
  }

  .active {
    background-color: $primary-main;
  }

  .cards {
    margin: 30px auto;
    padding: 30px 20px;
    max-width: 1600px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(300px, 600px);
    grid-column-gap: 40px;
    grid-row-gap: 10px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    // gap: 20px;
    // margin-bottom: 70px;
    overflow: hidden;
  }

  .card {
    position: relative;
    width: 100%;
    // margin-top: 50px;
    overflow: hidden;
    transition: all 0.5s;
    border:white solid 2px ;
    border-radius: 30px;





    .attach {
      width: 30px;
      right: 25px;
      top: 30px;
      z-index: 2;
      position: absolute;
    }

    .border {
      transition: all 0.5s;
    }

    .main-img {
      object-fit: contain;
      cursor: pointer;
      // object-position: top;
      // position: absolute;
      width: 100%;
      height: 'auto';
      // left: 25px;
      // top: 18px;
      // transition: all 0.5s;
      border-radius: 15px;

      // &:hover {
      //   scale: 1.04;
      // }
    }

    .tilt-img {
      will-change: transform;
      transform-style: preserve-3d;
    }


    // &:hover {
    //   .main-img {
    //     scale: 1.04;
    //   }

    //   .border {
    //     rotate: 3deg;
    //   }
    // }
  }

  .tilt-card {
    perspective: 1000px;
    /* Adds depth to the transformations */
    overflow: visible;
  }

  .card .svg-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10%;
    height: 10%;
    opacity: 0;
    // transition: opacity 0.3s ease-in-out;
    display: inline-block;
    transform: translate(-50%, -50%);
    filter: drop-shadow(8px 8px 10px black);
  }

  .card:hover .svg-overlay {
    opacity: 1;
  }

  .divider {
    position: relative;
    top: 0%;
    width: 70%;
    margin: 0 auto;
    border-top: 3px solid $white;
    border-radius: 10px;
  }

  @media #{$extra-device} {
    .cards {
      justify-content: center;
      align-items: center;
    }

    .card {
      position: relative;
      // width: 23%;
    }
  }

  @media #{$lg-layout} {
    .cards {
      justify-content: center;
      align-items: center;
    }

    .card {
      position: relative;
      // width: 32%;
    }
  }

  @media #{$md-layout} {
    .cards {
      justify-content: center;
      align-items: center;
    }

    .card {
      position: relative;
      // width: 45%;
    }
  }

  @media #{$sm-layout} {
    .tabs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      border: 2px solid white;
      border-radius: 40px;
      margin-top: 80px;
      padding: 5px;
    }

    .cards {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      padding: 30px 0px;
    }

    .card {
      position: relative;
      width: 100%;
    }
  }

  // loader css
  #loader-div {
    width: 100%;
    aspect-ratio: 3/1.5;
    display: grid;
    place-items: center;

    .loader {
      color: #fff;
      font-size: 10px;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      position: relative;
      text-indent: -9999em;
      animation: mulShdSpin 1.3s infinite linear;
      transform: translateZ(0);
    }

    @keyframes mulShdSpin {

      0%,
      100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
          0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
      }

      12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
          0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
      }

      25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
          0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
      }

      37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
          0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
      }

      50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
          0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
      }

      62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
          0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
      }

      75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
          2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
          -2em -2em 0 0;
      }

      87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
          0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
      }
    }
  }
}