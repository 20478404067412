.main {
  background-color: $black;
  padding: 35px 0;
  .para-bottom {
    font-size: max(18px, min(35px, 2vw));
    font-weight: 600;
    color: $white;
    text-align: center;
    padding: 15px;
    span {
      color: $primary-info;
    }
  }
  .btn {
    font-size: max(14px, min(22px, 3vw));
    background-color: $primary-main;
    color: $white;
    border-radius: 12px;
    padding: 15px 25px;
  }
}
